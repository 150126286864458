<template>
  <div class="Completed">
    <CompletedFlipstarters />
  </div>
</template>

<script>
// @ is an alias to /src
import CompletedFlipstarters from '@/components/CompletedFlipstarters.vue';

export default {
  name: 'Completed',
  components: {
    CompletedFlipstarters,
  },
};
</script>
