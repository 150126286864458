



























import { Component, Vue, Watch } from 'vue-property-decorator';
import { getFlipstarters } from '@/flipstarter';
import CampaignJumbotron from '@/components/CampaignJumbotron.vue';
import Intro from '@/components/Intro.vue';
import Loading from '@/components/Loading.vue';

Vue.filter('status', (status: string) => {
  //
  const uppercaseFirst = status.charAt(0).toUpperCase() + status.slice(1);
  return `Status: ${uppercaseFirst}`;
});

@Component({
  components: {
    CampaignJumbotron,
    Intro,
    Loading,
  },
})

export default class CompletedFlipstarters extends Vue {
  private filterOptions = [
    { text: 'Successful', value: 'success' },
    { text: 'Expired', value: 'expired' },
  ];

  private filterSelected = ['success'];

  private loadingFlipstarters = true;

  private error = '';

  // eslint-disable-next-line
  private flipstarters: any = [];

  private pagination = {
    perPage: 10,
    currentPage: 1,
    completed: this.flipstarters.filter((f: { status: string }) => {
      const s = f.status;
      return this.filterSelected.includes(s);
    }),
  }

  private rows = 0;

  created() {
    getFlipstarters().then((data) => {
      this.flipstarters = data;
      this.updatePagination();
    }).catch((error) => {
      this.error = error.message;
    }).then(() => {
      this.loadingFlipstarters = false;
    });
  }

  @Watch('filterSelected')
  onFilterChange() {
    this.updatePagination();
  }

  get pCompleted() {
    return this.pagination.completed.slice().reverse().slice((this.pagination.currentPage - 1)
      * this.pagination.perPage, this.pagination.currentPage * this.pagination.perPage);
  }

  updatePagination() {
    this.pagination = {
      perPage: 10,
      currentPage: 1,
      completed: this.flipstarters.filter((f: { status: string }) => {
        const s = f.status;
        return this.filterSelected.includes(s);
      }),
    };
    this.rows = this.pagination.completed.length;
  }
}
